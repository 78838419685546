/** ------------------- */
// ENV VARIABLES
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;
export const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

/** ------------------- */
// COLORS
export const bhColorWhite = "#FFF";
export const bhColorGray = "#F1F0F3";
export const bhColorDarkGray = "#D1D1D1";
export const bhColorVioletGray = "#6C6685";
export const bhColorDark = "#212121";
export const bhColorBlack = "#000";

export const bhColorError = "#FF4D4F";
export const bhColorWarning = "#FB5400";
export const bhColorSuccess = "#8200FF";
export const bhColorSuccess1 = "#0017ff";
export const bhColorPrimary = "#FF6C2F";
export const bhColorLightPrimary = "rgba(255, 108, 47, 0.25)";

/** ------------------- */
// LOCALSTORAGE KEYS
export const LS_AUTH_TOKEN_KEY = "auth_token";
export const LS_CART_DATA_LEY = "cart_data";

/** ------------------- */
// Message strings
export const msgFieldRequired = "This field is required.";
export const msgFieldInvalid = "This field is invalid.";
export const msgConfirmPasswordCorrectly = "Confirm password correctly.";

/** ------------------- */
// SYSTEM CONSTANTS

export const orderStatusColorsMap = {
    initiated: "orange",
    paid: "blue",
    shipping: "purple",
    succeeded: "green",
    canceled: "red",
};

export const orderStatusLabelMap = {
    initiated: "Initiated",
    paid: "Paid",
    shipping: "Shipping",
    succeeded: "Succeeded",
    canceled: "Canceled",
};

export const shipmentStatusColorsMap = {
    initiated: "orange",
    in_process: "purple",
    succeeded: "green",
    canceled: "red",
};

export const shipmentStatusLabelsMap = {
    initiated: "Not started",
    in_process: "In delivery",
    succeeded: "Succeeded",
    canceled: "Canceled",
};

export const socketStates = {
    0: "CONNECTING",
    1: "OPEN",
    2: "CLOSING",
    3: "CLOSED",
};
