import { combineReducers } from "redux";

import theme from "./theme";
import auth from "./auth";
import product from "./product";
import cart from "./cart";

export default combineReducers({
    theme,
    auth,
    product,
    cart,
});
