import { Country, State } from "country-state-city";

export const checkValidEmail = (email) => {
    const regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

export const getErrorMessage = (e) => {
    if (e.message === "Network Error") {
        return e.message;
    } else {
        if (e.response) {
            if (e.response.status === 401) {
                return "You are not authenticated, please login again!";
            } else if (e.response.status === 403) {
                return "You have no permission!";
            } else if (e.response.status === 404) {
                return "URL not found";
            } else if (e.response.status === 405) {
                return "Bad request";
            } else if (e.response.status === 500) {
                return "API error";
            } else {
                if (e.response?.data?.detail) {
                    return e.response.data.detail;
                } else {
                    return "Bad request";
                }
            }
        } else {
            return "API error";
        }
    }
};

export const fileToBase64Handler = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
    });
};

export const getSecuredEmail = (email) => {
    if (!email) return "";
    const arr = email.split("@");
    return `${arr[0][0]}********@${arr[1]}`;
};

export const getCartPrice = (cartProducts, products) => {
    if (!cartProducts || !products) return 0;
    let price = 0;
    cartProducts.forEach((cp) => {
        const product = products.find((p) => p.id === cp.product);
        if (product) {
            price += product.price * cp.amount;
        }
    });
    return price;
};

export const filterTextHandler = (text, searchKey) => {
    if (!text) return false;
    return (
        text
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
                searchKey
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            ) !== -1
    );
};

export const filterSelectOptionHandler = (input, option) => filterTextHandler(option.children, input);

export const getAddressText = (address) => {
    if (!address) return "";
    return `${address.address}, ${address.apartment ? `${address.apartment}, ` : ""} ${address.city}, ${
        State.getStateByCodeAndCountry(address.state, address.country)?.name
    }, ${Country.getCountryByCode(address.country)?.name}, ${address.postal_code}`;
};

export const convertUSDToSatoshi = (usd, rates) => {
    if (!usd || !rates) return 0;
    const rate = rates["usd"]["value"];
    const amountInBtc = usd / rate;
    const amountInSatoshi = amountInBtc * rates["sats"]["value"];
    return Math.round(amountInSatoshi);
};

export const getSocketRoomName = (email) => {
    return email.replace(/[^a-z0-9]/gi, "");
};
