import axios from "axios";

import * as actionTypes from "../actionTypes";
import { API_BASE_URL } from "../../../utils/constants";
import { errorManageHandler, updateLoading, updateMessage } from "./theme";

export const updateAddresses = (addresses) => {
    return {
        type: actionTypes.UPDATE_ADDRESSES,
        addresses,
    };
};

export const fetchAddresses = () => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        axios
            .get(`${API_BASE_URL}/store/addresses`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                dispatch(updateAddresses(res.data));
            })
            .catch((e) => dispatch(errorManageHandler(e)));
    };
};

export const createAddress = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .post(`${API_BASE_URL}/store/addresses`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { addresses } = getState().auth;
                if (addresses) {
                    dispatch(updateAddresses([res.data, ...addresses]));
                } else {
                    dispatch(updateAddresses([res.data]));
                }
                callback();
                dispatch(updateLoading(false));
                dispatch(updateMessage("success", "Address created successfully!"));
            })
            .catch((e) => dispatch(errorManageHandler(e)));
    };
};

export const updateAddress = (id, data, callback) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .put(`${API_BASE_URL}/store/address/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { addresses } = getState().auth;
                if (addresses) {
                    const newAddresses = addresses.map((a) => {
                        if (a.id === id) return res.data;
                        else return a;
                    });
                    dispatch(updateAddresses(newAddresses));
                }
                callback();
                dispatch(updateLoading(false));
                dispatch(updateMessage("success", "Address updated successfully!"));
            })
            .catch((e) => dispatch(errorManageHandler(e)));
    };
};

export const deleteAddress = (id) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .delete(`${API_BASE_URL}/store/address/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { addresses } = getState().auth;
                if (addresses) {
                    const newAddresses = addresses.filter((a) => a.id !== id);
                    dispatch(updateAddresses(newAddresses));
                }
                dispatch(updateLoading(false));
                dispatch(updateMessage("success", "Address deleted successfully!"));
            })
            .catch((e) => dispatch(errorManageHandler(e)));
    };
};
