import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rgba } from "polished";

import { Card } from "antd";

const props = {
    children: PropTypes.any,
    className: PropTypes.string,

    border: PropTypes.string,
    shadow: PropTypes.bool,
    padding: PropTypes.number,

    onClick: PropTypes.func,
};

const BHCard = ({ children, className, border, shadow, onClick, padding = 24 }) => {
    return (
        <Wrapper
            className={className}
            border={border}
            shadow={(shadow !== undefined ? shadow : true).toString()}
            padding={padding}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            {children}
        </Wrapper>
    );
};

const Wrapper = styled(Card)`
    border: ${(props) => (props.border ? `1px solid ${props.border}` : "none")};
    box-shadow: ${(props) => (props.shadow === "true" ? `0 5px 8px ${rgba(props.theme.colorDarkGray, 0.5)}` : "none")};

    overflow: visible;

    .ant-card-body {
        padding: ${(props) => (props.padding !== undefined ? props.padding : "24")}px;

        &::before,
        &::after {
            display: none;
        }
    }
`;

BHCard.propTypes = props;

export default BHCard;
