import styled from "styled-components";

import { Form } from "antd";

const { Item } = Form;

export const BHFormItem = styled(Item)`
    .ant-form-item-row {
        .ant-form-item-label {
            padding-bottom: 2px;
        }
    }

    &.required {
        .ant-form-item-label {
            label {
                ::before {
                    content: '*';

                    display: inline-block;

                    margin-right: 4px;

                    font-family: "SimSun", sans-serif;
                    font-size: 14px;
                    line-height: 1;
                    color: ${props => props.theme.colorRed};
                }
            }
        }
    }
`;