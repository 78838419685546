import * as actionTypes from "../actionTypes";

import { getErrorMessage } from "../../../utils/helpers";
import { logout } from "./auth";

export const updateLoading = (loading) => {
    return {
        type: actionTypes.UPDATE_LOADING,
        loading,
    };
};

export const updateMessage = (type, content) => {
    return {
        type: actionTypes.UPDATE_MESSAGE,
        message: {
            type,
            content,
        },
    };
};

export const errorManageHandler = (e, callback = null) => {
    console.log(e);
    return (dispatch) => {
        if (e.response.status === 401) {
            dispatch(logout());
        } else {
            const errorMessage = getErrorMessage(e);
            dispatch(updateMessage("error", errorMessage));
        }
        if (callback) callback();
        dispatch(updateLoading(false));
    };
};
