import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    topProducts: null,
    products: null,
    total: 0,
    filter: {
        page: 1,
    },
};

const updateTopProducts = (state, action) => {
    return updateState(state, {
        topProducts: action.topProducts,
    });
};

const updateProducts = (state, action) => {
    return updateState(state, {
        products: action.products,
        total: action.total,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TOP_PRODUCTS:
            return updateTopProducts(state, action);
        case actionTypes.UPDATE_PRODUCTS:
            return updateProducts(state, action);
        case actionTypes.UPDATE_PRODUCT_FILTER:
            return updateFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
