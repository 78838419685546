import { LS_CART_DATA_LEY } from "../../../utils/constants";

import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    dhlZones: null,
    dhlShipCosts: null,
    products: JSON.parse(localStorage.getItem(LS_CART_DATA_LEY) || "[]"),
    rates: null,
};

const updateDHLZones = (state, action) => {
    return updateState(state, {
        dhlZones: action.dhlZones,
    });
};

const updateDHLShipCosts = (state, action) => {
    return updateState(state, {
        dhlShipCosts: action.dhlShipCosts,
    });
};

const updateProducts = (state, action) => {
    return updateState(state, {
        products: action.products,
    });
};

const updateRates = (state, action) => {
    return updateState(state, {
        rates: action.rates,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DHL_ZONES:
            return updateDHLZones(state, action);
        case actionTypes.UPDATE_DHL_SHIP_COSTS:
            return updateDHLShipCosts(state, action);
        case actionTypes.UPDATE_CART_PRODUCTS:
            return updateProducts(state, action);
        case actionTypes.UPDATE_RATES:
            return updateRates(state, action);
        default:
            return state;
    }
};

export default reducer;
