import styled from "styled-components";

import { Tabs } from "antd";

export const BHTabs = styled(Tabs)`
    .ant-tabs-nav {
        &::before {
            border-bottom-color: ${(props) => props.theme.colorBlack};
        }

        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    font-weight: 500;
                }

                .ant-tabs-ink-bar {
                    height: 4px;
                    border-radius: 4px 4px 0 0;
                }
            }
        }
    }
`;
