import React, { useEffect } from "react";
import Loadable from "react-loadable";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ConfigProvider, notification } from "antd";
import { ThemeProvider } from "styled-components";
import { Loader } from "./components/base";

import { antdThemes, styledThemes } from "./utils/themes";

import { updateMessage } from "./services/store/actions/theme";

import en from "antd/locale/en_US";

const loading = () => (
    <>
        <Loader />
    </>
);

const Dashboard = Loadable({
    loader: () => import("./pages/Dashboard/Dashboard"),
    loading,
});

const Orders = Loadable({
    loader: () => import("./pages/Order/Orders"),
    loading,
});

const OrderDetail = Loadable({
    loader: () => import("./pages/Order/Detail"),
    loading,
});

const Addresses = Loadable({
    loader: () => import("./pages/Address/Addresses"),
    loading,
});

const Profile = Loadable({
    loader: () => import("./pages/Profile/Profile"),
    loading,
});

const MainLayout = Loadable({
    loader: () => import("./layouts/MainLayout/MainLayout"),
    loading,
});

const App = () => {
    const dispatch = useDispatch();

    const { message } = useSelector((state) => state.theme);

    useEffect(() => {
        if (message.content !== null) {
            notification[message.type]({
                message: message.type.toUpperCase(),
                description: message.content,
                onClose: () => dispatch(updateMessage(null, null)),
            });
        }
    }, [message.content]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ConfigProvider theme={antdThemes["light"]} locale={en}>
            <ThemeProvider theme={styledThemes["light"]}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/dashboard/orders" element={<Orders />} />
                        <Route path="/dashboard/orders/:orderId" element={<OrderDetail />} />
                        <Route path="/dashboard/addresses" element={<Addresses />} />
                        <Route path="/dashboard/profile" element={<Profile />} />
                        <Route path="/*" element={<MainLayout />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </ConfigProvider>
    );
};

export default App;
