export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_ADDRESSES = "UPDATE_ADDRESSES";

export const UPDATE_TOP_PRODUCTS = "UPDATE_TOP_PRODUCTS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const UPDATE_PRODUCT_FILTER = "UPDATE_PRODUCT_FILTER";

export const UPDATE_DHL_ZONES = "UPDATE_DHL_ZONES";
export const UPDATE_DHL_SHIP_COSTS = "UPDATE_DHL_SHIP_COSTS";
export const UPDATE_CART_PRODUCTS = "UPDATE_CART_PRODUCTS";
export const UPDATE_RATES = "UPDATE_RATES";
